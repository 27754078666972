/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
// import Img from "gatsby-image"
import { AiOutlineRight } from "react-icons/ai"

import "array-flat-polyfill"

import { isBrowser } from "react-device-detect"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useWindowSize } from "react-use"

import "./index.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import Container from "../components/container"
import FilmItem from "../components/film-item"
import ArtNumItem from "../components/artnum-item"
import PersonneItem from "../components/personne-item"
import ActualiteItem from "../components/actualite-item"
import HomeMosaic from "../components/home-mosaic"

const LineBoxContainer = props => (
  <div
    {...props}
    sx={{
      alignItems: "stretch",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      overflowY: "hidden",
      WebkitOverflowScrolling: "touch",
      "& > div": {
        margin: "0px 10px",
        "&:first-of-type": {
          marginLeft: "0px",
        },
        "&:last-of-type": {
          marginRight: "0px",
        },
      },
    }}
  />
)

const LineBox = ({ children, to }) => {
  return (
    <div sx={{ overflowY: "hidden" }}>
      {/* <></> doesn't work without importing React */}
      <LineBoxContainer>{children}</LineBoxContainer>
    </div>
  )
}

const SectionTitle1 = ({ to, children }) => (
  <div sx={{ pb: 4 }}>
    <Link to={to} as="div" sx={{ textDecoration: "none" }}>
      <Section.Title1
        sx={{
          pl: 2, // to compensate the left icon on the right
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
        {to && <AiOutlineRight color={"#CCC"} size={"0.6em"} sx={{ ml: 1 }} />}
      </Section.Title1>
    </Link>
  </div>
)

const SectionTitle2 = ({ to, children }) => (
  <div sx={{ pt: 4, pb: 4 }}>
    <Link to={to} as="div" sx={{ textDecoration: "none" }}>
      <Section.Title2
        sx={{
          pl: 2, // to compensate the left icon on the right
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
        {to && <AiOutlineRight color={"#CCC"} size={"0.7em"} sx={{ ml: 1 }} />}
      </Section.Title2>
    </Link>
  </div>
)

const SliderLine = ({ width, children }) => {
  const windowSize = useWindowSize()
  const [slideWidth, setSlideWidth] = useState(null)

  useEffect(() => {
    setSlideWidth(Math.round(windowSize.width / width))
  }, [width, windowSize.width])

  return (
    slideWidth && (
      <Slider
        sx={{ mx: "-10px", my: 0 }}
        dots={false}
        infinite={false}
        slidesToShow={slideWidth}
        slidesToScroll={slideWidth}
        // lazyLoad={"progressive"}
        arrows={true}
        speed={700}
      >
        {children}
      </Slider>
    )
  )
}

const EnProd01 = ({ data, ...props }) => (
  <div
    sx={{
      px: 2,
      position: "relative",
      paddingBottom: "127%",
    }}
  >
    <div
      sx={{
        position: "absolute",
        top: "0px",
        left: "0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        px: 2,
      }}
    >
      <Link
        to={"film#enproduction"}
        as="div"
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "#F7F7F7",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 4,
          // borderTop: "2px solid #F77",
          // borderBottom: "2px solid #F77",
          textDecoration: "none",
          transition: "background-color 0.1s ease-in-out",
          ":hover": {
            backgroundColor: "#F0F0F0",
          },
        }}
      >
        <div
          sx={{
            mb: "60%",
            textAlign: "center",
            textTransform: "uppercase",
            fontWeight: 400,
            color: "#707070",
          }}
        >
          {/* {data.filmsSoon.nodes.length} */}
          film
          {data.filmsSoon.nodes.length > 1 && "s"} <br />
          en
          <br />
          production
        </div>
      </Link>
    </div>
  </div>
)

const IndexPage = ({ data, ...props }) => {
  const realisateurs = data.realisateurs.nodes.filter(
    personne => personne.lastSortieFilm
  )
  const artistes = data.artistes.nodes.filter(
    personne => personne.lastSortieArtNum
  )

  let isGrey = false

  return (
    <Layout>
      <SEO title="Home" />

      {/* <HomeCarousel slideshow={data.slideshow.nodes} /> */}
      <HomeMosaic actualites={data.actualites.nodes} />

      <Section isGrey={isGrey}>
        <Container isFull={true}>
          <SectionTitle1 to="/film/">FILMS</SectionTitle1>
          {isBrowser ? (
            <SliderLine width={200}>
              {data.filmsSoon.nodes.length > 0 && <EnProd01 data={data} />}
              {data.films.nodes.map(film => (
                <div sx={{ px: 2 }} key={film.id}>
                  <FilmItem isFluid={true} film={film} />
                </div>
              ))}
            </SliderLine>
          ) : (
            <LineBox>
              {data.films.nodes.map(film => (
                <FilmItem isFluid={false} key={film.id} film={film} />
              ))}
            </LineBox>
          )}
          <SectionTitle2 to={"/realisateur"}>CINÉASTES</SectionTitle2>
          {isBrowser ? (
            <SliderLine width={170}>
              {realisateurs.map(personne => (
                <div sx={{ px: 2 }} key={personne.id}>
                  <PersonneItem isFluid={true} personne={personne} />
                </div>
              ))}
            </SliderLine>
          ) : (
            <LineBox to={"/realisateur/"}>
              {realisateurs.map(personne => (
                <PersonneItem key={personne.id} personne={personne} />
              ))}
            </LineBox>
          )}
        </Container>
      </Section>

      <Section isGrey={(isGrey = !isGrey)}>
        <Container isFull={true}>
          <SectionTitle1 to="/artnum/">ART NUMÉRIQUE</SectionTitle1>
          {isBrowser ? (
            <SliderLine width={400}>
              {data.artNum.nodes.map(artNum => (
                <div sx={{ px: 2 }} key={artNum.id}>
                  <ArtNumItem isFluid={true} artNum={artNum} />
                </div>
              ))}
            </SliderLine>
          ) : (
            <LineBox>
              {data.artNum.nodes.map(artNum => (
                <ArtNumItem key={artNum.id} artNum={artNum} />
              ))}
            </LineBox>
          )}
          <SectionTitle2 to={"/artnum/#artistes"}>ARTISTES</SectionTitle2>
          {isBrowser ? (
            <SliderLine width={170}>
              {artistes.map(personne => (
                <div sx={{ px: 2 }} key={personne.id}>
                  <PersonneItem isFluid={true} personne={personne} />
                </div>
              ))}
            </SliderLine>
          ) : (
            <LineBox>
              {artistes.map(personne => (
                <PersonneItem key={personne.id} personne={personne} />
              ))}
            </LineBox>
          )}
        </Container>
      </Section>

      <Section isGrey={(isGrey = !isGrey)}>
        <Container isFull={true}>
          <SectionTitle1 to="/actualite">ACTUALITÉS</SectionTitle1>
          {isBrowser ? (
            <SliderLine width={290}>
              {data.actualites.nodes.map(actualite => (
                <div sx={{ px: 2 }} key={actualite.id}>
                  <ActualiteItem isFluid={true} actualite={actualite} />
                </div>
              ))}
            </SliderLine>
          ) : (
            <LineBox to={"/actualite/"}>
              {data.actualites.nodes.map(actualite => (
                <ActualiteItem key={actualite.id} actualite={actualite} />
              ))}
            </LineBox>
          )}
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    films: allBubbleCreation(
      sort: { fields: Sortie, order: DESC }
      filter: {
        isArtNum: { eq: false }
        # Affiche_optimized: { absolutePath: { ne: null } }
        DELETED: { ne: true }
        Statut_: { eq: "1555693173056x139561298485978430" } #films sortis
      } # limit: 12
    ) {
      nodes {
        id
        Titre_FR
        slug
        Affiche_optimized {
          childImageSharp {
            fluid(
              maxWidth: 210
              maxHeight: 290
              quality: 85
              cropFocus: CENTER
            ) {
              # ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    artNum: allBubbleCreation(
      sort: { fields: Sortie, order: DESC }
      filter: {
        isArtNum: { eq: true }
        # Affiche_optimized: { ne: null }
        DELETED: { ne: true }
        Statut_: { eq: "1555693173056x139561298485978430" } #artnum sortis
      } # limit: 8
    ) {
      nodes {
        id
        Titre_FR
        CACHE_Realisateurs
        slug
        Affiche_optimized {
          childImageSharp {
            fluid(
              maxWidth: 400
              maxHeight: 255
              quality: 85
              cropFocus: CENTER
            ) {
              # ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    realisateurs: allBubblePersonne(
      sort: { fields: lastSortieFilm, order: DESC }
      filter: {
        isReal: { eq: true }
        DELETED: { ne: true }
        # PUBLIE: {eq: true},
        # Featured: {eq: true}
        lastSortieFilm: { ne: null }
      } # limit: 14
    ) {
      nodes {
        Image_principale_optimized {
          childImageSharp {
            fluid(maxWidth: 170, maxHeight: 170, quality: 85) {
              # ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluid
            }
          }
        }
        CACHE_Nom_complet
        id
        slug
        lastSortieFilm
      }
    }

    artistes: allBubblePersonne(
      sort: { fields: lastSortieArtNum, order: DESC }
      filter: {
        isArtiste: { eq: true }
        DELETED: { ne: true }
        # PUBLIE: {eq: true},
        # Featured: {eq: true}
        lastSortieArtNum: { ne: null }
      } # limit: 14
    ) {
      nodes {
        Image_principale_optimized {
          childImageSharp {
            fluid(maxWidth: 170, maxHeight: 170, quality: 85) {
              # ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluid
            }
          }
        }
        CACHE_Nom_complet
        id
        slug
        lastSortieArtNum
      }
    }

    slideshow: allBubbleSlideshow(
      sort: { fields: Priority, order: ASC }
      filter: { DELETED: { ne: true }, Featured: { eq: true } }
    ) {
      nodes {
        id
        Image
        Titre_FR
        Description_FR
        Created_Date
        DELETED
        Image_optimized {
          childImageSharp {
            fluid(
              maxWidth: 1600
              maxHeight: 730
              quality: 85
              cropFocus: CENTER
            ) {
              # ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    actualites: allBubbleActualite(
      sort: { fields: Created_Date, order: DESC }
      filter: { DELETED: { ne: true } }
    ) {
      nodes {
        Description_FR
        Source
        Titre_FR
        DELETED
        PUBLIE
        Featured
        id
        Lien_URL
        Image_principale_optimized {
          childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              quality: 85
              cropFocus: CENTER
            ) {
              # ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluid
            }
          }
        }
        Creations {
          slug
          isArtNum
        }
      }
    }

    filmsSoon: allBubbleCreation(
      filter: {
        Statut_: { ne: "1555693173056x139561298485978430" }
        DELETED: { ne: true }
      }
    ) {
      nodes {
        Titre_FR
        slug
        id
      }
    }

    enProdImage: file(relativePath: { eq: "enProd02.jpg" }) {
      childImageSharp {
        fluid(
          maxWidth: 210
          maxHeight: 290
          cropFocus: CENTER
          quality: 85 # grayscale: true
        ) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default IndexPage
